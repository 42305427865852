import React, { Fragment } from 'react';
import { Stack, Chip, Box } from '@mui/material';
import SurfingRoundedIcon from '@mui/icons-material/SurfingRounded';
import TimeToLeaveRoundedIcon from '@mui/icons-material/TimeToLeaveRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import moment from 'moment';

import Tooltip from '../ui/Tooltip';

export default function MonthlyColumns(props) {
	const row = props.row.original;
	const yearMonth = props.yearMonth;
	const persons = row.tickLogs[yearMonth];

	const Icons = (props) => {
		const { person } = props;

		if (!person || !person.flag) {
			return null;
		}

		return (
			<>
				{person.flag.Sabbatical && (
					<Tooltip text={`Sabbatical for ${person.flag.sabbaticalDays} days.`}>
						<SurfingRoundedIcon fontSize='small' />
					</Tooltip>
				)}

				{person.flag.LOA && (
					<Tooltip text={`Leave of Absence for ${person.flag.loaDays} days.`}>
						<TimeToLeaveRoundedIcon fontSize='small' />
					</Tooltip>
				)}
				{person.flag.startDateFlag?.setting && (
					<Tooltip
						text={`${person.personName} started their role for this position on ${moment(
							person.flag.startDateFlag.date.toDate()
						).format('MMM D, YYYY')} for a total of ${person.flag.monthlyDays} days this month.`}
					>
						<CalendarMonthRoundedIcon fontSize='small' />
					</Tooltip>
				)}
				{person.flag.endDateFlag?.setting && (
					<Tooltip
						text={`${person.personName} ended their role for this position on ${moment(
							person.flag.endDateFlag.date.toDate()
						).format('MMM D, YYYY')} for a total of ${person.flag.monthlyDays} days this month.`}
					>
						<CalendarMonthRoundedIcon fontSize='small' />
					</Tooltip>
				)}
			</>
		);
	};

	return (
		<Box display='flex' justifyContent='center'>
			<Stack>
				{persons.map((person, index) => {
					return (
						<Fragment key={person.log}>
							{index > 0 ? <br /> : ''}
							<Stack direction='row' spacing={1}>
								<Chip
									icon={<Icons person={person} />}
									label={`${person.personName}: ${Math.round(person.totalHours * 10) / 10} of ${
										Math.round(person.allottedHours * 10) / 10
									} hours`}
									variant='outlined'
									clickable
									component='a'
									target='_blank'
									href={person.log}
									color='primary'
									sx={{
										display: 'flex',
										minHeight: '2rem',
										height: 'auto',
										justifyContent: 'center',
										textAlign: 'center',
										alignItems: 'center',
										'& .MuiChip-label': {
											display: 'flex',
											wordWrap: 'normal',
											whiteSpace: 'normal',
											textOverflow: 'clip',
											textAlign: 'center',
											justifyContent: 'center',
											width: '100px',
										},
										padding: '2px',
									}}
								/>
							</Stack>
						</Fragment>
					);
				})}
			</Stack>
		</Box>
	);
}
