import React, { useEffect, useState, Fragment, useContext, useMemo } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Grid, Box, InputLabel, MenuItem, FormControl, Select, Stack, Chip, Typography } from '@mui/material';
import {MaterialReactTable} from 'material-react-table';

import PageCards from '../components/ui/PageCards';
import AuthContext from '../components/auth/authContext';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import MonthlyColumns from '../components/tickLogs/MonthlyColumns';
import WETColumnsTotalYearly from '../components/tickLogs/WETColumnsTotalYearly';
import WETColumnsTotalQuarterly from '../components/tickLogs/WETColumnsTotalQuarterly';
import WETColumnsTotalBiannual from '../components/tickLogs/WETColumnsTotalBiannual';
import CATStipendColumnsTotalQuarterly from '../components/tickLogs/CATStipendColumnsTotalQuarterly';
import CATStipendColumnsTotalYearly from '../components/tickLogs/CATStipendColumnsTotalYearly';
import CATStipendColumnsTotalBiannual from '../components/tickLogs/CATStipendColumnsTotalBiannual';
import { db } from '../App';

export default function TickLogs() {
	const authCtx = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [wetData, setWETData] = useState([]);
	const [catStipendData, setCATStipendData] = useState([]);
	const [quarter, setQuarter] = useState('Yearly');
	const [year, setYear] = useState();
	const [maxYear, setMaxYear] = useState();
	const [years, setYears] = useState([]);
	const [firstYearMonth, setFirstYearMonth] = useState();
	const [secondYearMonth, setSecondYearMonth] = useState();
	const [thirdYearMonth, setThirdYearMonth] = useState();
	const [fourthYearMonth, setFourthYearMonth] = useState();
	const [fifthYearMonth, setFifthYearMonth] = useState();
	const [sixthYearMonth, setSixthYearMonth] = useState();
	const [logType, setLogType] = useState('WET');

	//sets the initial month and year and therefor the quarter
	useEffect(() => {
		const date = new Date();
		let newDate;

		//if its the 2nd of the month or later and at or after 4AM, then show the prior month
		if (date.getDate() > 1 && date.getHours() > 4) {
			newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()); //subtracts 2 incase its on day 2 but before 4AM
		}
		//else show 2 months prior, as the tick logs will not have downloaded yet
		else {
			newDate = new Date(date.getFullYear(), date.getMonth() - 2);
		}

		//Q1
		if (newDate.getMonth() === 1 || newDate.getMonth() === 2 || newDate.getMonth() === 3) {
			setYear(newDate.getFullYear());
			setMaxYear(newDate.getFullYear());
		}
		//Q2
		if (newDate.getMonth() === 4 || newDate.getMonth() === 5 || newDate.getMonth() === 6) {
			setYear(newDate.getFullYear());
			setMaxYear(newDate.getFullYear());
		}
		//Q3
		if (newDate.getMonth() === 7 || newDate.getMonth() === 8 || newDate.getMonth() === 9) {
			setYear(newDate.getFullYear());
			setMaxYear(newDate.getFullYear());
		}
		//Q4 - Nov and Dec
		if (newDate.getMonth() === 10 || newDate.getMonth() === 11) {
			setYear(newDate.getFullYear());
			setMaxYear(newDate.getFullYear());
		}
		//Q4 - Jan
		if (newDate.getMonth() === 0) {
			setYear(newDate.getFullYear() - 1);
			setMaxYear(newDate.getFullYear() - 1);
		}
	}, []);

	//sets years array for Select Year input
	useEffect(() => {
		if (maxYear) {
			let tempYear = maxYear;
			const tempArray = [];

			do {
				tempArray.push(tempYear);
				tempYear -= 1;
			} while (tempYear >= 2022);

			setYears(tempArray);
		}
	}, [maxYear]);

	//initial load - gets the leadership positions & sets tableData, loads allPeople
	useEffect(() => {
		if (db) {
			const getData = async () => {
				setIsLoading(true);
				setWETData([]);
				setCATStipendData([]);

				const wetData = [];
				const catStipendData = [];
				const querySnapshot = await getDocs(collection(db, 'leadership'));
				querySnapshot.forEach((document) => {
					let data = document.data();
					// console.log(data);
					if (data.currentPerson.startDate && data.currentPerson.startDate !== null) {
						data.currentPerson.startDate = data.currentPerson.startDate.toDate();
					}
					if (data.currentPerson.endDate && data.currentPerson.endDate !== null) {
						data.currentPerson.endDate = data.currentPerson.endDate.toDate();
					}
					if (data.futurePerson.startDate && data.futurePerson.startDate !== null) {
						data.futurePerson.startDate = data.futurePerson.startDate.toDate();
					}
					if (data.futurePerson.endDate && data.futurePerson.endDate !== null) {
						data.futurePerson.endDate = data.futurePerson.endDate.toDate();
					}
					if (data.active && data.tickLogs && data.compensationType === 'WET') {
						wetData.push({ id: document.id, ...data });
					}
					if (
						data.active &&
						data.tickLogs &&
						(data.compensationType === 'CAT' || data.compensationType === 'Stipend')
					) {
						catStipendData.push({ id: document.id, ...data });
					}
				});

				setWETData(() => wetData);
				setCATStipendData(() => catStipendData);

				setIsLoading(false);
			};

			getData();
		}
	}, [db, authCtx.token]);

	//handles change to the quarter
	const handleQuarterChange = (event) => {
		setQuarter(event.target.value);
	};

	//handles change to the log type
	const handleLogTypeChange = (event) => {
		setLogType(event.target.value);
	};

	//handles change to the year
	const handleYearChange = (e) => {
		setYear(e.target.value);
	};

	//sets yearMonths for quarterly periods
	useEffect(() => {
		if (quarter !== 'Yearly') {
			//Q1
			if (quarter === 'Q1') {
				setFirstYearMonth(`${year}-02`);
				setSecondYearMonth(`${year}-03`);
				setThirdYearMonth(`${year}-04`);
			}

			//Q2
			else if (quarter === 'Q2') {
				setFirstYearMonth(`${year}-05`);
				setSecondYearMonth(`${year}-06`);
				setThirdYearMonth(`${year}-07`);
			}

			//Q3
			else if (quarter === 'Q3') {
				setFirstYearMonth(`${year}-08`);
				setSecondYearMonth(`${year}-09`);
				setThirdYearMonth(`${year}-10`);
			}
			//Q4
			else if (quarter === 'Q4') {
				setFirstYearMonth(`${year}-11`);
				setSecondYearMonth(`${year}-12`);
				setThirdYearMonth(`${year + 1}-01`);
			}
			//Q1 & Q2
			else if (quarter === 'Q1Q2') {
				setFirstYearMonth(`${year}-02`);
				setSecondYearMonth(`${year}-03`);
				setThirdYearMonth(`${year}-04`);
				setFourthYearMonth(`${year}-05`);
				setFifthYearMonth(`${year}-06`);
				setSixthYearMonth(`${year}-07`);
			}
			//Q3 & Q4
			else if (quarter === 'Q3Q4') {
				setFirstYearMonth(`${year}-08`);
				setSecondYearMonth(`${year}-09`);
				setThirdYearMonth(`${year}-10`);
				setFourthYearMonth(`${year}-11`);
				setFifthYearMonth(`${year}-12`);
				setSixthYearMonth(`${year + 1}-01`);
			}
		}
	}, [year, quarter]);

	//WET table columns
	const columnsWET = useMemo(() => {
		//quarters
		if (quarter !== 'Yearly' && quarter !== 'Q1Q2' && quarter !== 'Q3Q4') {
			return [
				//id
				{
					accessorKey: 'id',
					header: 'ID',
				},
				//position title
				{
					id: 'positionTitle',
					accessorFn: (row) => (row.positionTitle ? `${row.positionTitle}` : ''),
					header: 'Position',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					Cell: ({ row }) => (
						<Box display='flex' justifyContent='center'>
							<Stack direction='row' spacing={1}>
								<Chip
									label={row.original.positionTitle}
									variant='filled'
									color='primary'
									sx={{
										display: 'flex',
										minHeight: '2rem',
										height: 'auto',
										justifyContent: 'center',
										textAlign: 'center',
										alignItems: 'center',
										'& .MuiChip-label': {
											display: 'flex',
											wordWrap: 'normal',
											whiteSpace: 'normal',
											textOverflow: 'clip',
											textAlign: 'center',
											justifyContent: 'center',
											width: '150px',
										},
										padding: '2px',
									}}
								/>
							</Stack>
						</Box>
					),
				},
				//totals
				{
					id: 'totals',
					size: 100,
					header: 'Total',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					Cell: ({ row }) => (
						<WETColumnsTotalQuarterly
							row={row}
							firstYearMonth={firstYearMonth}
							secondYearMonth={secondYearMonth}
							thirdYearMonth={thirdYearMonth}
						/>
					),
				},
				//first month data
				{
					id: 'firstMonthHours',
					header: quarter === 'Q1Q2' ? `February ${year}` : `August ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${firstYearMonth}`]
							? row.tickLogs[`${firstYearMonth}`][2]
								? `${row.tickLogs[`${firstYearMonth}`][2].personName} ${
										row.tickLogs[`${firstYearMonth}`][1].personName
								  } ${row.tickLogs[`${firstYearMonth}`][0].personName}`
								: row.tickLogs[`${firstYearMonth}`][1]
								? `${row.tickLogs[`${firstYearMonth}`][1].personName} ${
										row.tickLogs[`${firstYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${firstYearMonth}`][0]
								? row.tickLogs[`${firstYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${firstYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${firstYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//second month data
				{
					id: `secondMonthHours`,
					header: quarter === 'Q1Q2' ? `March ${year}` : `September ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${secondYearMonth}`]
							? row.tickLogs[`${secondYearMonth}`][2]
								? `${row.tickLogs[`${secondYearMonth}`][2].personName} ${
										row.tickLogs[`${secondYearMonth}`][1].personName
								  } ${row.tickLogs[`${secondYearMonth}`][0].personName}`
								: row.tickLogs[`${secondYearMonth}`][1]
								? `${row.tickLogs[`${secondYearMonth}`][1].personName} ${
										row.tickLogs[`${secondYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${secondYearMonth}`][0]
								? row.tickLogs[`${secondYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${secondYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${secondYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//third month data
				{
					id: 'thirdMonthHours',
					header: quarter === 'Q1Q2' ? `April ${year}` : `October ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${thirdYearMonth}`]
							? row.tickLogs[`${thirdYearMonth}`][2]
								? `${row.tickLogs[`${thirdYearMonth}`][2].personName} ${
										row.tickLogs[`${thirdYearMonth}`][1].personName
								  } ${row.tickLogs[`${thirdYearMonth}`][0].personName}`
								: row.tickLogs[`${thirdYearMonth}`][1]
								? `${row.tickLogs[`${thirdYearMonth}`][1].personName} ${
										row.tickLogs[`${thirdYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${thirdYearMonth}`][0]
								? row.tickLogs[`${thirdYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${thirdYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${thirdYearMonth}`} />
						) : (
							'No Data'
						),
				},
			];
		}
		//biannual
		if (quarter === 'Q1Q2' || quarter === 'Q3Q4') {
			return [
				//id
				{
					accessorKey: 'id',
					header: 'ID',
				},
				//position title
				{
					id: 'positionTitle',
					accessorFn: (row) => (row.positionTitle ? `${row.positionTitle}` : ''),
					header: 'Position',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					Cell: ({ row }) => (
						<Box display='flex' justifyContent='center'>
							<Stack direction='row' spacing={1}>
								<Chip
									label={row.original.positionTitle}
									variant='filled'
									color='primary'
									sx={{
										display: 'flex',
										minHeight: '2rem',
										height: 'auto',
										justifyContent: 'center',
										textAlign: 'center',
										alignItems: 'center',
										'& .MuiChip-label': {
											display: 'flex',
											wordWrap: 'normal',
											whiteSpace: 'normal',
											textOverflow: 'clip',
											textAlign: 'center',
											justifyContent: 'center',
											width: '150px',
										},
										padding: '2px',
									}}
								/>
							</Stack>
						</Box>
					),
				},
				//totals
				{
					id: 'totals',
					size: 100,
					header: 'Total',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					Cell: ({ row }) => (
						<WETColumnsTotalBiannual
							row={row}
							firstYearMonth={firstYearMonth}
							secondYearMonth={secondYearMonth}
							thirdYearMonth={thirdYearMonth}
							fourthYearMonth={fourthYearMonth}
							fifthYearMonth={fifthYearMonth}
							sixthYearMonth={sixthYearMonth}
						/>
					),
				},
				//first month data
				{
					id: 'firstMonthHours',
					header: quarter === 'Q1Q2' ? `February ${year}` : `August ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${firstYearMonth}`]
							? row.tickLogs[`${firstYearMonth}`][2]
								? `${row.tickLogs[`${firstYearMonth}`][2].personName} ${
										row.tickLogs[`${firstYearMonth}`][1].personName
								  } ${row.tickLogs[`${firstYearMonth}`][0].personName}`
								: row.tickLogs[`${firstYearMonth}`][1]
								? `${row.tickLogs[`${firstYearMonth}`][1].personName} ${
										row.tickLogs[`${firstYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${firstYearMonth}`][0]
								? row.tickLogs[`${firstYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${firstYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${firstYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//second month data
				{
					id: `secondMonthHours`,
					header: quarter === 'Q1Q2' ? `March ${year}` : `September ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${secondYearMonth}`]
							? row.tickLogs[`${secondYearMonth}`][2]
								? `${row.tickLogs[`${secondYearMonth}`][2].personName} ${
										row.tickLogs[`${secondYearMonth}`][1].personName
								  } ${row.tickLogs[`${secondYearMonth}`][0].personName}`
								: row.tickLogs[`${secondYearMonth}`][1]
								? `${row.tickLogs[`${secondYearMonth}`][1].personName} ${
										row.tickLogs[`${secondYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${secondYearMonth}`][0]
								? row.tickLogs[`${secondYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${secondYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${secondYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//third month data
				{
					id: 'thirdMonthHours',
					header: quarter === 'Q1Q2' ? `April ${year}` : `October ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${thirdYearMonth}`]
							? row.tickLogs[`${thirdYearMonth}`][2]
								? `${row.tickLogs[`${thirdYearMonth}`][2].personName} ${
										row.tickLogs[`${thirdYearMonth}`][1].personName
								  } ${row.tickLogs[`${thirdYearMonth}`][0].personName}`
								: row.tickLogs[`${thirdYearMonth}`][1]
								? `${row.tickLogs[`${thirdYearMonth}`][1].personName} ${
										row.tickLogs[`${thirdYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${thirdYearMonth}`][0]
								? row.tickLogs[`${thirdYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${thirdYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${thirdYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//fourth month data
				{
					id: 'fourthMonthHours',
					header: quarter === 'Q1Q2' ? `May ${year}` : `November ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${fourthYearMonth}`]
							? row.tickLogs[`${fourthYearMonth}`][2]
								? `${row.tickLogs[`${fourthYearMonth}`][2].personName} ${
										row.tickLogs[`${fourthYearMonth}`][1].personName
								  } ${row.tickLogs[`${fourthYearMonth}`][0].personName}`
								: row.tickLogs[`${fourthYearMonth}`][1]
								? `${row.tickLogs[`${fourthYearMonth}`][1].personName} ${
										row.tickLogs[`${fourthYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${fourthYearMonth}`][0]
								? row.tickLogs[`${fourthYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${fourthYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${fourthYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//fifth month data
				{
					id: 'fifthMonthHours',
					header: quarter === 'Q1Q2' ? `June ${year}` : `December ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${fifthYearMonth}`]
							? row.tickLogs[`${fifthYearMonth}`][2]
								? `${row.tickLogs[`${fifthYearMonth}`][2].personName} ${
										row.tickLogs[`${fifthYearMonth}`][1].personName
								  } ${row.tickLogs[`${fifthYearMonth}`][0].personName}`
								: row.tickLogs[`${fifthYearMonth}`][1]
								? `${row.tickLogs[`${fifthYearMonth}`][1].personName} ${
										row.tickLogs[`${fifthYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${fifthYearMonth}`][0]
								? row.tickLogs[`${fifthYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${fifthYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${fifthYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//sixth month data
				{
					id: 'sixthMonthHours',
					header: quarter === 'Q1Q2' ? `July ${year}` : `January ${year + 1}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${sixthYearMonth}`]
							? row.tickLogs[`${sixthYearMonth}`][2]
								? `${row.tickLogs[`${sixthYearMonth}`][2].personName} ${
										row.tickLogs[`${sixthYearMonth}`][1].personName
								  } ${row.tickLogs[`${sixthYearMonth}`][0].personName}`
								: row.tickLogs[`${sixthYearMonth}`][1]
								? `${row.tickLogs[`${sixthYearMonth}`][1].personName} ${
										row.tickLogs[`${sixthYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${sixthYearMonth}`][0]
								? row.tickLogs[`${sixthYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${sixthYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${sixthYearMonth}`} />
						) : (
							'No Data'
						),
				},
			];
		}
		//else return the yearly data
		else {
			return [
				//id
				{
					accessorKey: 'id',
					header: 'ID',
				},
				//position title
				{
					id: 'positionTitle',
					accessorFn: (row) => (row.positionTitle ? `${row.positionTitle}` : ''),
					header: 'Position',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					Cell: ({ row }) => (
						<Box display='flex' justifyContent='center'>
							<Stack direction='row' spacing={1}>
								<Chip
									label={row.original.positionTitle}
									variant='filled'
									color='primary'
									sx={{
										display: 'flex',
										minHeight: '2rem',
										height: 'auto',
										justifyContent: 'center',
										textAlign: 'center',
										alignItems: 'center',
										'& .MuiChip-label': {
											display: 'flex',
											wordWrap: 'normal',
											whiteSpace: 'normal',
											textOverflow: 'clip',
											textAlign: 'center',
											justifyContent: 'center',
											width: '150px',
										},
										padding: '2px',
									}}
								/>
							</Stack>
						</Box>
					),
				},
				//totals
				{
					id: 'totals',
					size: 100,
					header: 'Total',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					Cell: ({ row }) => <WETColumnsTotalYearly row={row} year={year} />,
				},
				//february data
				{
					id: 'february',
					size: 100,
					header: 'February',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false, // disable sorting for this column
					accessorFn: (row) =>
						row.tickLogs[`${year}-02`]
							? row.tickLogs[`${year}-02`][2]
								? `${row.tickLogs[`${year}-02`][2].personName} ${row.tickLogs[`${year}-02`][1].personName} ${
										row.tickLogs[`${year}-02`][0].personName
								  }`
								: row.tickLogs[`${year}-02`][1]
								? `${row.tickLogs[`${year}-02`][1].personName} ${row.tickLogs[`${year}-02`][0].personName}`
								: row.tickLogs[`${year}-02`][0].personName
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-02`] ? <MonthlyColumns row={row} yearMonth={`${year}-02`} /> : 'No Data',
				},
				//march data
				{
					id: 'march',
					size: 100,
					header: 'March',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false, // disable sorting for this column
					accessorFn: (row) =>
						row.tickLogs[`${year}-03`]
							? row.tickLogs[`${year}-03`][2]
								? `${row.tickLogs[`${year}-03`][2].personName} ${row.tickLogs[`${year}-03`][1].personName} ${
										row.tickLogs[`${year}-03`][0].personName
								  }`
								: row.tickLogs[`${year}-03`][1]
								? `${row.tickLogs[`${year}-03`][1].personName} ${row.tickLogs[`${year}-03`][0].personName}`
								: row.tickLogs[`${year}-03`][0]
								? row.tickLogs[`${year}-03`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-03`] ? <MonthlyColumns row={row} yearMonth={`${year}-03`} /> : 'No Data',
				},
				//april data
				{
					id: 'april',
					size: 100,
					header: 'April',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false, // disable sorting for this column
					accessorFn: (row) =>
						row.tickLogs[`${year}-04`]
							? row.tickLogs[`${year}-04`][2]
								? `${row.tickLogs[`${year}-04`][2].personName} ${row.tickLogs[`${year}-04`][1].personName} ${
										row.tickLogs[`${year}-04`][0].personName
								  }`
								: row.tickLogs[`${year}-04`][1]
								? `${row.tickLogs[`${year}-04`][1].personName} ${row.tickLogs[`${year}-04`][0].personName}`
								: row.tickLogs[`${year}-04`][0]
								? row.tickLogs[`${year}-04`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-04`] ? <MonthlyColumns row={row} yearMonth={`${year}-04`} /> : 'No Data',
				},
				//may data
				{
					id: 'may',
					size: 100,
					header: 'May',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false, // disable sorting for this column
					accessorFn: (row) =>
						row.tickLogs[`${year}-05`]
							? row.tickLogs[`${year}-05`][2]
								? `${row.tickLogs[`${year}-05`][2].personName} ${row.tickLogs[`${year}-05`][1].personName} ${
										row.tickLogs[`${year}-05`][0].personName
								  }`
								: row.tickLogs[`${year}-05`][1]
								? `${row.tickLogs[`${year}-05`][1].personName} ${row.tickLogs[`${year}-05`][0].personName}`
								: row.tickLogs[`${year}-05`][0]
								? row.tickLogs[`${year}-05`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-05`] ? <MonthlyColumns row={row} yearMonth={`${year}-05`} /> : 'No Data',
				},
				//june data
				{
					id: 'june',
					size: 100,
					header: 'June',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-06`]
							? row.tickLogs[`${year}-06`][2]
								? `${row.tickLogs[`${year}-06`][2].personName} ${row.tickLogs[`${year}-06`][1].personName} ${
										row.tickLogs[`${year}-06`][0].personName
								  }`
								: row.tickLogs[`${year}-06`][1]
								? `${row.tickLogs[`${year}-06`][1].personName} ${row.tickLogs[`${year}-06`][0].personName}`
								: row.tickLogs[`${year}-06`][0]
								? row.tickLogs[`${year}-06`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-06`] ? <MonthlyColumns row={row} yearMonth={`${year}-06`} /> : 'No Data',
				},
				//july data
				{
					id: 'july',
					size: 100,
					header: 'July',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-07`]
							? row.tickLogs[`${year}-07`][2]
								? `${row.tickLogs[`${year}-07`][2].personName} ${row.tickLogs[`${year}-07`][1].personName} ${
										row.tickLogs[`${year}-07`][0].personName
								  }`
								: row.tickLogs[`${year}-07`][1]
								? `${row.tickLogs[`${year}-07`][1].personName} ${row.tickLogs[`${year}-07`][0].personName}`
								: row.tickLogs[`${year}-07`][0]
								? row.tickLogs[`${year}-07`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-07`] ? <MonthlyColumns row={row} yearMonth={`${year}-07`} /> : 'No Data',
				},
				//august data
				{
					id: 'august',
					size: 100,
					header: 'August',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-08`]
							? row.tickLogs[`${year}-08`][2]
								? `${row.tickLogs[`${year}-08`][2].personName} ${row.tickLogs[`${year}-08`][1].personName} ${
										row.tickLogs[`${year}-08`][0].personName
								  }`
								: row.tickLogs[`${year}-08`][1]
								? `${row.tickLogs[`${year}-08`][1].personName} ${row.tickLogs[`${year}-08`][0].personName}`
								: row.tickLogs[`${year}-08`][0]
								? row.tickLogs[`${year}-08`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-08`] ? <MonthlyColumns row={row} yearMonth={`${year}-08`} /> : 'No Data',
				},
				//september data
				{
					id: 'september',
					size: 100,
					header: 'September',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-09`]
							? row.tickLogs[`${year}-09`][2]
								? `${row.tickLogs[`${year}-09`][2].personName} ${row.tickLogs[`${year}-09`][1].personName} ${
										row.tickLogs[`${year}-09`][0].personName
								  }`
								: row.tickLogs[`${year}-09`][1]
								? `${row.tickLogs[`${year}-09`][1].personName} ${row.tickLogs[`${year}-09`][0].personName}`
								: row.tickLogs[`${year}-09`][0]
								? row.tickLogs[`${year}-09`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-09`] ? <MonthlyColumns row={row} yearMonth={`${year}-09`} /> : 'No Data',
				},
				//october data
				{
					id: 'october',
					size: 100,
					header: 'October',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-10`]
							? row.tickLogs[`${year}-10`][2]
								? `${row.tickLogs[`${year}-10`][2].personName} ${row.tickLogs[`${year}-10`][1].personName} ${
										row.tickLogs[`${year}-10`][0].personName
								  }`
								: row.tickLogs[`${year}-10`][1]
								? `${row.tickLogs[`${year}-10`][1].personName} ${row.tickLogs[`${year}-10`][0].personName}`
								: row.tickLogs[`${year}-10`][0]
								? row.tickLogs[`${year}-10`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-10`] ? <MonthlyColumns row={row} yearMonth={`${year}-10`} /> : 'No Data',
				},
				//november data
				{
					id: 'november',
					size: 100,
					header: 'November',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-11`]
							? row.tickLogs[`${year}-11`][2]
								? `${row.tickLogs[`${year}-11`][2].personName} ${row.tickLogs[`${year}-11`][1].personName} ${
										row.tickLogs[`${year}-11`][0].personName
								  }`
								: row.tickLogs[`${year}-11`][1]
								? `${row.tickLogs[`${year}-11`][1].personName} ${row.tickLogs[`${year}-11`][0].personName}`
								: row.tickLogs[`${year}-11`][0]
								? row.tickLogs[`${year}-11`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-11`] ? <MonthlyColumns row={row} yearMonth={`${year}-11`} /> : 'No Data',
				},
				//december data
				{
					id: 'december',
					size: 100,
					header: 'December',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-12`]
							? row.tickLogs[`${year}-12`][2]
								? `${row.tickLogs[`${year}-12`][2].personName} ${row.tickLogs[`${year}-12`][1].personName} ${
										row.tickLogs[`${year}-12`][0].personName
								  }`
								: row.tickLogs[`${year}-12`][1]
								? `${row.tickLogs[`${year}-12`][1].personName} ${row.tickLogs[`${year}-12`][0].personName}`
								: row.tickLogs[`${year}-12`][0]
								? row.tickLogs[`${year}-12`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-12`] ? <MonthlyColumns row={row} yearMonth={`${year}-12`} /> : 'No Data',
				},
				//january data
				{
					id: 'january',
					size: 100,
					header: 'January',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year + 1}-01`]
							? row.tickLogs[`${year + 1}-01`][2]
								? `${row.tickLogs[`${year + 1}-01`][2].personName} ${row.tickLogs[`${year + 1}-01`][1].personName} ${
										row.tickLogs[`${year + 1}-01`][0].personName
								  }`
								: row.tickLogs[`${year + 1}-01`][1]
								? `${row.tickLogs[`${year + 1}-01`][1].personName} ${row.tickLogs[`${year + 1}-01`][0].personName}`
								: row.tickLogs[`${year + 1}-01`][0]
								? row.tickLogs[`${year + 1}-01`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year + 1}-01`] ? (
							<MonthlyColumns row={row} yearMonth={`${year + 1}-01`} />
						) : (
							'No Data'
						),
				},
			];
		}
	}, [firstYearMonth, secondYearMonth, thirdYearMonth, quarter, year]);

	//CAT & Stipend table columns
	const columnsCATStipend = useMemo(() => {
		//quarters
		if (quarter !== 'Yearly' && quarter !== 'Q1Q2' && quarter !== 'Q3Q4') {
			return [
				//id
				{
					accessorKey: 'id',
					header: 'ID',
				},
				//position title
				{
					id: 'positionTitle',
					accessorFn: (row) => (row.positionTitle ? `${row.positionTitle}` : ''),
					header: 'Position',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					Cell: ({ row }) => (
						<Box display='flex' justifyContent='center'>
							<Stack direction='row' spacing={1}>
								<Chip
									label={row.original.positionTitle}
									variant='filled'
									color='primary'
									sx={{
										display: 'flex',
										minHeight: '2rem',
										height: 'auto',
										justifyContent: 'center',
										textAlign: 'center',
										alignItems: 'center',
										'& .MuiChip-label': {
											display: 'flex',
											wordWrap: 'normal',
											whiteSpace: 'normal',
											textOverflow: 'clip',
											textAlign: 'center',
											justifyContent: 'center',
											width: '150px',
										},
										padding: '2px',
									}}
								/>
							</Stack>
						</Box>
					),
				},
				//totals
				{
					id: 'totals',
					size: 100,
					header: 'Total',
					enableSorting: false,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					Cell: ({ row }) => (
						<CATStipendColumnsTotalQuarterly
							row={row}
							firstYearMonth={firstYearMonth}
							secondYearMonth={secondYearMonth}
							thirdYearMonth={thirdYearMonth}
						/>
					),
				},
				//first month data
				{
					id: 'firstMonthHours',
					header: quarter === 'Q1Q2' ? `February ${year}` : `August ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${firstYearMonth}`]
							? row.tickLogs[`${firstYearMonth}`][2]
								? `${row.tickLogs[`${firstYearMonth}`][2].personName} ${
										row.tickLogs[`${firstYearMonth}`][1].personName
								  } ${row.tickLogs[`${firstYearMonth}`][0].personName}`
								: row.tickLogs[`${firstYearMonth}`][1]
								? `${row.tickLogs[`${firstYearMonth}`][1].personName} ${
										row.tickLogs[`${firstYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${firstYearMonth}`][0]
								? row.tickLogs[`${firstYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${firstYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${firstYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//second month data
				{
					id: `secondMonthHours`,
					header: quarter === 'Q1Q2' ? `March ${year}` : `September ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${secondYearMonth}`]
							? row.tickLogs[`${secondYearMonth}`][2]
								? `${row.tickLogs[`${secondYearMonth}`][2].personName} ${
										row.tickLogs[`${secondYearMonth}`][1].personName
								  } ${row.tickLogs[`${secondYearMonth}`][0].personName}`
								: row.tickLogs[`${secondYearMonth}`][1]
								? `${row.tickLogs[`${secondYearMonth}`][1].personName} ${
										row.tickLogs[`${secondYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${secondYearMonth}`][0]
								? row.tickLogs[`${secondYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${secondYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${secondYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//third month data
				{
					id: 'thirdMonthHours',
					header: quarter === 'Q1Q2' ? `April ${year}` : `October ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${thirdYearMonth}`]
							? row.tickLogs[`${thirdYearMonth}`][2]
								? `${row.tickLogs[`${thirdYearMonth}`][2].personName} ${
										row.tickLogs[`${thirdYearMonth}`][1].personName
								  } ${row.tickLogs[`${thirdYearMonth}`][0].personName}`
								: row.tickLogs[`${thirdYearMonth}`][1]
								? `${row.tickLogs[`${thirdYearMonth}`][1].personName} ${
										row.tickLogs[`${thirdYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${thirdYearMonth}`][0]
								? row.tickLogs[`${thirdYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${thirdYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${thirdYearMonth}`} />
						) : (
							'No Data'
						),
				},
			];
		}
		//biannual
		if (quarter === 'Q1Q2' || quarter === 'Q3Q4') {
			return [
				//id
				{
					accessorKey: 'id',
					header: 'ID',
				},
				//position title
				{
					id: 'positionTitle',
					accessorFn: (row) => (row.positionTitle ? `${row.positionTitle}` : ''),
					header: 'Position',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					Cell: ({ row }) => (
						<Box display='flex' justifyContent='center'>
							<Stack direction='row' spacing={1}>
								<Chip
									label={row.original.positionTitle}
									variant='filled'
									color='primary'
									sx={{
										display: 'flex',
										minHeight: '2rem',
										height: 'auto',
										justifyContent: 'center',
										textAlign: 'center',
										alignItems: 'center',
										'& .MuiChip-label': {
											display: 'flex',
											wordWrap: 'normal',
											whiteSpace: 'normal',
											textOverflow: 'clip',
											textAlign: 'center',
											justifyContent: 'center',
											width: '150px',
										},
										padding: '2px',
									}}
								/>
							</Stack>
						</Box>
					),
				},
				//totals
				{
					id: 'totals',
					size: 100,
					header: 'Total',
					enableSorting: false,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					Cell: ({ row }) => (
						<CATStipendColumnsTotalBiannual
							row={row}
							firstYearMonth={firstYearMonth}
							secondYearMonth={secondYearMonth}
							thirdYearMonth={thirdYearMonth}
							fourthYearMonth={fourthYearMonth}
							fifthYearMonth={fifthYearMonth}
							sixthYearMonth={sixthYearMonth}
						/>
					),
				},
				//first month data
				{
					id: 'firstMonthHours',
					header: quarter === 'Q1Q2' ? `February ${year}` : `August ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${firstYearMonth}`]
							? row.tickLogs[`${firstYearMonth}`][2]
								? `${row.tickLogs[`${firstYearMonth}`][2].personName} ${
										row.tickLogs[`${firstYearMonth}`][1].personName
								  } ${row.tickLogs[`${firstYearMonth}`][0].personName}`
								: row.tickLogs[`${firstYearMonth}`][1]
								? `${row.tickLogs[`${firstYearMonth}`][1].personName} ${
										row.tickLogs[`${firstYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${firstYearMonth}`][0]
								? row.tickLogs[`${firstYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${firstYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${firstYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//second month data
				{
					id: `secondMonthHours`,
					header: quarter === 'Q1Q2' ? `March ${year}` : `September ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${secondYearMonth}`]
							? row.tickLogs[`${secondYearMonth}`][2]
								? `${row.tickLogs[`${secondYearMonth}`][2].personName} ${
										row.tickLogs[`${secondYearMonth}`][1].personName
								  } ${row.tickLogs[`${secondYearMonth}`][0].personName}`
								: row.tickLogs[`${secondYearMonth}`][1]
								? `${row.tickLogs[`${secondYearMonth}`][1].personName} ${
										row.tickLogs[`${secondYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${secondYearMonth}`][0]
								? row.tickLogs[`${secondYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${secondYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${secondYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//third month data
				{
					id: 'thirdMonthHours',
					header: quarter === 'Q1Q2' ? `April ${year}` : `October ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${thirdYearMonth}`]
							? row.tickLogs[`${thirdYearMonth}`][2]
								? `${row.tickLogs[`${thirdYearMonth}`][2].personName} ${
										row.tickLogs[`${thirdYearMonth}`][1].personName
								  } ${row.tickLogs[`${thirdYearMonth}`][0].personName}`
								: row.tickLogs[`${thirdYearMonth}`][1]
								? `${row.tickLogs[`${thirdYearMonth}`][1].personName} ${
										row.tickLogs[`${thirdYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${thirdYearMonth}`][0]
								? row.tickLogs[`${thirdYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${thirdYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${thirdYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//fourth month data
				{
					id: 'fourthMonthHours',
					header: quarter === 'Q1Q2' ? `May ${year}` : `November ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${fourthYearMonth}`]
							? row.tickLogs[`${fourthYearMonth}`][2]
								? `${row.tickLogs[`${fourthYearMonth}`][2].personName} ${
										row.tickLogs[`${fourthYearMonth}`][1].personName
								  } ${row.tickLogs[`${fourthYearMonth}`][0].personName}`
								: row.tickLogs[`${fourthYearMonth}`][1]
								? `${row.tickLogs[`${fourthYearMonth}`][1].personName} ${
										row.tickLogs[`${fourthYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${fourthYearMonth}`][0]
								? row.tickLogs[`${fourthYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${fourthYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${fourthYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//fifth month data
				{
					id: 'fifthMonthHours',
					header: quarter === 'Q1Q2' ? `June ${year}` : `December ${year}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${fifthYearMonth}`]
							? row.tickLogs[`${fifthYearMonth}`][2]
								? `${row.tickLogs[`${fifthYearMonth}`][2].personName} ${
										row.tickLogs[`${fifthYearMonth}`][1].personName
								  } ${row.tickLogs[`${fifthYearMonth}`][0].personName}`
								: row.tickLogs[`${fifthYearMonth}`][1]
								? `${row.tickLogs[`${fifthYearMonth}`][1].personName} ${
										row.tickLogs[`${fifthYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${fifthYearMonth}`][0]
								? row.tickLogs[`${fifthYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${fifthYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${fifthYearMonth}`} />
						) : (
							'No Data'
						),
				},
				//sixth month data
				{
					id: 'sixthMonthHours',
					header: quarter === 'Q1Q2' ? `July ${year}` : `January ${year + 1}`,
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${sixthYearMonth}`]
							? row.tickLogs[`${sixthYearMonth}`][2]
								? `${row.tickLogs[`${sixthYearMonth}`][2].personName} ${
										row.tickLogs[`${sixthYearMonth}`][1].personName
								  } ${row.tickLogs[`${sixthYearMonth}`][0].personName}`
								: row.tickLogs[`${sixthYearMonth}`][1]
								? `${row.tickLogs[`${sixthYearMonth}`][1].personName} ${
										row.tickLogs[`${sixthYearMonth}`][0].personName
								  }`
								: row.tickLogs[`${sixthYearMonth}`][0]
								? row.tickLogs[`${sixthYearMonth}`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${sixthYearMonth}`] ? (
							<MonthlyColumns row={row} yearMonth={`${sixthYearMonth}`} />
						) : (
							'No Data'
						),
				},
			];
		}
		//else display the yearly data
		else {
			return [
				//id
				{
					accessorKey: 'id',
					header: 'ID',
				},
				//position title
				{
					id: 'positionTitle',
					accessorFn: (row) => (row.positionTitle ? `${row.positionTitle}` : ''),
					header: 'Position',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					Cell: ({ row }) => (
						<Box display='flex' justifyContent='center'>
							<Stack direction='row' spacing={1}>
								<Chip
									label={row.original.positionTitle}
									variant='filled'
									color='primary'
									sx={{
										display: 'flex',
										minHeight: '2rem',
										height: 'auto',
										justifyContent: 'center',
										textAlign: 'center',
										alignItems: 'center',
										'& .MuiChip-label': {
											display: 'flex',
											wordWrap: 'normal',
											whiteSpace: 'normal',
											textOverflow: 'clip',
											textAlign: 'center',
											justifyContent: 'center',
											width: '150px',
										},
										padding: '2px',
									}}
								/>
							</Stack>
						</Box>
					),
				},
				//totals
				{
					id: 'totals',
					size: 100,
					header: 'Total',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					Cell: ({ row }) => <CATStipendColumnsTotalYearly row={row} year={year} />,
				},
				//february data
				{
					id: 'february',
					size: 100,
					header: 'February',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false, // disable sorting for this column
					accessorFn: (row) =>
						row.tickLogs[`${year}-02`]
							? row.tickLogs[`${year}-02`][2]
								? `${row.tickLogs[`${year}-02`][2].personName} ${row.tickLogs[`${year}-02`][1].personName} ${
										row.tickLogs[`${year}-02`][0].personName
								  }`
								: row.tickLogs[`${year}-02`][1]
								? `${row.tickLogs[`${year}-02`][1].personName} ${row.tickLogs[`${year}-02`][0].personName}`
								: row.tickLogs[`${year}-02`][0]
								? row.tickLogs[`${year}-02`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-02`] ? <MonthlyColumns row={row} yearMonth={`${year}-02`} /> : 'No Data',
				},
				//march data
				{
					id: 'march',
					size: 100,
					header: 'March',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false, // disable sorting for this column
					accessorFn: (row) =>
						row.tickLogs[`${year}-03`]
							? row.tickLogs[`${year}-03`][2]
								? `${row.tickLogs[`${year}-03`][2].personName} ${row.tickLogs[`${year}-03`][1].personName} ${
										row.tickLogs[`${year}-03`][0].personName
								  }`
								: row.tickLogs[`${year}-03`][1]
								? `${row.tickLogs[`${year}-03`][1].personName} ${row.tickLogs[`${year}-03`][0].personName}`
								: row.tickLogs[`${year}-03`][0]
								? row.tickLogs[`${year}-03`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-03`] ? <MonthlyColumns row={row} yearMonth={`${year}-03`} /> : 'No Data',
				},
				//april data
				{
					id: 'april',
					size: 100,
					header: 'April',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false, // disable sorting for this column
					accessorFn: (row) =>
						row.tickLogs[`${year}-04`]
							? row.tickLogs[`${year}-04`][2]
								? `${row.tickLogs[`${year}-04`][2].personName} ${row.tickLogs[`${year}-04`][1].personName} ${
										row.tickLogs[`${year}-04`][0].personName
								  }`
								: row.tickLogs[`${year}-04`][1]
								? `${row.tickLogs[`${year}-04`][1].personName} ${row.tickLogs[`${year}-04`][0].personName}`
								: row.tickLogs[`${year}-04`][0]
								? row.tickLogs[`${year}-04`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-04`] ? <MonthlyColumns row={row} yearMonth={`${year}-04`} /> : 'No Data',
				},
				//may data
				{
					id: 'may',
					size: 100,
					header: 'May',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false, // disable sorting for this column
					accessorFn: (row) =>
						row.tickLogs[`${year}-05`]
							? row.tickLogs[`${year}-05`][2]
								? `${row.tickLogs[`${year}-05`][2].personName} ${row.tickLogs[`${year}-05`][1].personName} ${
										row.tickLogs[`${year}-05`][0].personName
								  }`
								: row.tickLogs[`${year}-05`][1]
								? `${row.tickLogs[`${year}-05`][1].personName} ${row.tickLogs[`${year}-05`][0].personName}`
								: row.tickLogs[`${year}-05`][0]
								? row.tickLogs[`${year}-05`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-05`] ? <MonthlyColumns row={row} yearMonth={`${year}-05`} /> : 'No Data',
				},
				//june data
				{
					id: 'june',
					size: 100,
					header: 'June',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-06`]
							? row.tickLogs[`${year}-06`][2]
								? `${row.tickLogs[`${year}-06`][2].personName} ${row.tickLogs[`${year}-06`][1].personName} ${
										row.tickLogs[`${year}-06`][0].personName
								  }`
								: row.tickLogs[`${year}-06`][1]
								? `${row.tickLogs[`${year}-06`][1].personName} ${row.tickLogs[`${year}-06`][0].personName}`
								: row.tickLogs[`${year}-06`][0]
								? row.tickLogs[`${year}-06`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-06`] ? <MonthlyColumns row={row} yearMonth={`${year}-06`} /> : 'No Data',
				},
				//july data
				{
					id: 'july',
					size: 100,
					header: 'July',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-07`]
							? row.tickLogs[`${year}-07`][2]
								? `${row.tickLogs[`${year}-07`][2].personName} ${row.tickLogs[`${year}-07`][1].personName} ${
										row.tickLogs[`${year}-07`][0].personName
								  }`
								: row.tickLogs[`${year}-07`][1]
								? `${row.tickLogs[`${year}-07`][1].personName} ${row.tickLogs[`${year}-07`][0].personName}`
								: row.tickLogs[`${year}-07`][0]
								? row.tickLogs[`${year}-07`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-07`] ? <MonthlyColumns row={row} yearMonth={`${year}-07`} /> : 'No Data',
				},
				//august data
				{
					id: 'august',
					size: 100,
					header: 'August',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-08`]
							? row.tickLogs[`${year}-08`][2]
								? `${row.tickLogs[`${year}-08`][2].personName} ${row.tickLogs[`${year}-08`][1].personName} ${
										row.tickLogs[`${year}-08`][0].personName
								  }`
								: row.tickLogs[`${year}-08`][1]
								? `${row.tickLogs[`${year}-08`][1].personName} ${row.tickLogs[`${year}-08`][0].personName}`
								: row.tickLogs[`${year}-08`][0]
								? row.tickLogs[`${year}-08`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-08`] ? <MonthlyColumns row={row} yearMonth={`${year}-08`} /> : 'No Data',
				},
				//september data
				{
					id: 'september',
					size: 100,
					header: 'September',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-09`]
							? row.tickLogs[`${year}-09`][2]
								? `${row.tickLogs[`${year}-09`][2].personName} ${row.tickLogs[`${year}-09`][1].personName} ${
										row.tickLogs[`${year}-09`][0].personName
								  }`
								: row.tickLogs[`${year}-09`][1]
								? `${row.tickLogs[`${year}-09`][1].personName} ${row.tickLogs[`${year}-09`][0].personName}`
								: row.tickLogs[`${year}-09`][0]
								? row.tickLogs[`${year}-09`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-09`] ? <MonthlyColumns row={row} yearMonth={`${year}-09`} /> : 'No Data',
				},
				//october data
				{
					id: 'october',
					size: 100,
					header: 'October',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-10`]
							? row.tickLogs[`${year}-10`][2]
								? `${row.tickLogs[`${year}-10`][2].personName} ${row.tickLogs[`${year}-10`][1].personName} ${
										row.tickLogs[`${year}-10`][0].personName
								  }`
								: row.tickLogs[`${year}-10`][1]
								? `${row.tickLogs[`${year}-10`][1].personName} ${row.tickLogs[`${year}-10`][0].personName}`
								: row.tickLogs[`${year}-10`][0]
								? row.tickLogs[`${year}-10`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-10`] ? <MonthlyColumns row={row} yearMonth={`${year}-10`} /> : 'No Data',
				},
				//november data
				{
					id: 'november',
					size: 100,
					header: 'November',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-11`]
							? row.tickLogs[`${year}-11`][2]
								? `${row.tickLogs[`${year}-11`][2].personName} ${row.tickLogs[`${year}-11`][1].personName} ${
										row.tickLogs[`${year}-11`][0].personName
								  }`
								: row.tickLogs[`${year}-11`][1]
								? `${row.tickLogs[`${year}-11`][1].personName} ${row.tickLogs[`${year}-11`][0].personName}`
								: row.tickLogs[`${year}-11`][0]
								? row.tickLogs[`${year}-11`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-11`] ? <MonthlyColumns row={row} yearMonth={`${year}-11`} /> : 'No Data',
				},
				//december data
				{
					id: 'december',
					size: 100,
					header: 'December',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year}-12`]
							? row.tickLogs[`${year}-12`][2]
								? `${row.tickLogs[`${year}-12`][2].personName} ${row.tickLogs[`${year}-12`][1].personName} ${
										row.tickLogs[`${year}-12`][0].personName
								  }`
								: row.tickLogs[`${year}-12`][1]
								? `${row.tickLogs[`${year}-12`][1].personName} ${row.tickLogs[`${year}-12`][0].personName}`
								: row.tickLogs[`${year}-12`][0]
								? row.tickLogs[`${year}-12`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year}-12`] ? <MonthlyColumns row={row} yearMonth={`${year}-12`} /> : 'No Data',
				},
				//january data
				{
					id: 'january',
					size: 100,
					header: 'January',
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
					enableSorting: false,
					accessorFn: (row) =>
						row.tickLogs[`${year + 1}-01`]
							? row.tickLogs[`${year + 1}-01`][2]
								? `${row.tickLogs[`${year + 1}-01`][2].personName} ${row.tickLogs[`${year + 1}-01`][1].personName} ${
										row.tickLogs[`${year + 1}-01`][0].personName
								  }`
								: row.tickLogs[`${year + 1}-01`][1]
								? `${row.tickLogs[`${year + 1}-01`][1].personName} ${row.tickLogs[`${year + 1}-01`][0].personName}`
								: row.tickLogs[`${year + 1}-01`][0]
								? row.tickLogs[`${year + 1}-01`][0].personName
								: ''
							: '',
					Cell: ({ row }) =>
						row.original.tickLogs[`${year + 1}-01`] ? (
							<MonthlyColumns row={row} yearMonth={`${year + 1}-01`} />
						) : (
							'No Data'
						),
				},
			];
		}
	}, [year, quarter, firstYearMonth, secondYearMonth, thirdYearMonth]);

	//display if loading
	if (isLoading) {
		return (
			<PageCards>
				<Typography variant='h3' textAlign='center' color={'primary'}>
					Tick Logs
				</Typography>
				<LoadingSpinner />
			</PageCards>
		);
	}

	return (
		<Fragment>
			<PageCards>
				<Typography variant='h3' textAlign='center' mb={2} color={'primary'}>
					Tick Logs
				</Typography>

				{/* log inputs*/}
				<Grid container justifyContent='flex-end' sx={{ display: { xs: 'block' } }}>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						{/* select year */}
						<FormControl sx={{ marginLeft: '1rem', width: '150px' }}>
							<InputLabel id='Select Year'>Select Year</InputLabel>
							<Select
								labelId='Select Year'
								id='Select Year'
								value={`${year}`}
								label='Select Year'
								onChange={handleYearChange}
							>
								{years.map((year) => {
									return (
										<MenuItem key={year} value={year}>
											{year}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						{/* time period (i.e. year, quarter, biannual) */}
						<FormControl sx={{ marginLeft: '1rem', width: '150px' }}>
							<InputLabel id='quarterSelectLabel'>Time Period</InputLabel>
							<Select
								labelId='quarterSelectLabel'
								id='quarterSelect'
								value={quarter}
								label='Time Period'
								onChange={handleQuarterChange}
							>
								<MenuItem value='Yearly'>Yearly</MenuItem>
								<MenuItem value='Q1'>Q1</MenuItem>
								<MenuItem value='Q2'>Q2</MenuItem>
								<MenuItem value='Q3'>Q3</MenuItem>
								<MenuItem value='Q4'>Q4</MenuItem>
								<MenuItem value='Q1Q2'>Q1 & Q2</MenuItem>
								<MenuItem value='Q3Q4'>Q3 & Q4</MenuItem>
							</Select>
						</FormControl>

						{/* log type input */}
						<FormControl sx={{ marginLeft: '1rem', width: '150px' }}>
							<InputLabel id='logTypeLabel'>Log Type</InputLabel>
							<Select
								labelId='logTypeLabel'
								id='logType'
								value={logType}
								label='Log Type'
								onChange={handleLogTypeChange}
							>
								<MenuItem value='WET'>WET</MenuItem>
								<MenuItem value='CAT/Stipend'>CAT/Stipend</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</Grid>
			</PageCards>

			<PageCards>
				<Grid container justifyContent='flex-end' sx={{ display: { xs: 'block' } }}>
					<Box mb={-2} sx={{ display: 'flex', justifyContent: 'center' }}>
						{logType === 'WET' ? (
							<Typography variant='h4' color='primary' mb={2} textAlign={'center'}>
								WET Logs
							</Typography>
						) : (
							<Typography variant='h4' color='primary' mb={2} textAlign={'center'}>
								CAT & Stipend Logs
							</Typography>
						)}
					</Box>
					<MaterialReactTable
						muiTablePaperProps={{
							elevation: 0, //change the mui box shadow
							sx: {
								//stripe the rows, make even rows a darker color
								'& tr:nth-of-type(even)': {
									backgroundColor: '#f5f5f5',
								},
							},
						}}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								muiTableHeadCellProps: {
									align: 'center',
								},
								size: 120,
							},
						}}
						columns={logType === 'WET' ? columnsWET : columnsCATStipend}
						data={logType === 'WET' ? wetData : catStipendData}
						enableColumnActions={false}
						enablePinning
						positionPagination='both'
						initialState={{
							density: 'compact',
							expanded: true, //expand all groups by default
							pagination: { pageIndex: 0, pageSize: 15 },
							sorting: [{ id: 'positionTitle', desc: false }], //sort by group by default
							columnVisibility: {
								id: false,
							},
							columnPinning: { left: ['positionTitle', 'totals'] },
						}}
					/>
				</Grid>
			</PageCards>
		</Fragment>
	);
}
