import React, { useEffect, useState, Fragment, useMemo, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { Box, Typography } from '@mui/material';
import PageCards from '../components/ui/PageCards';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import AlertContext from '../components/ui/AlertContext';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';

export default function UniqueIDs() {
	const claims = useContext(CustomClaimsContext);
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);

	//table columns
	const columns = useMemo(
		() => [
			{
				id: 'name',
				accessorKey: 'name.fullName',
				header: 'Name',
			},
			{
				accessorKey: 'customSchemas.EPT_Details.UniqueID',
				header: 'Unique ID',
			},
		],
		[]
	);

	//initial load - gets the data
	useEffect(() => {
		if (!data) {
			setIsLoading(true);
			const functions = getFunctions();
			const getMemberInfo = httpsCallable(functions, 'getUniqueIDs');
			getMemberInfo({ admin: claims?.claims?.admin }).then((result) => {
				if (result.data.code === 200) setData(result.data.data);
				else {
					alertCtx.setSeverity(result.data.status.toLowerCase());
					alertCtx.setMessage(result.data.message);
					alertCtx.setActive(true);
					setIsLoading(false);
				}
			});
		}
	}, [claims?.claims?.admin]);

	//turns off loading once data set
	useEffect(() => {
		if (data) setIsLoading(false);
	}, [data]);

	const table = useMaterialReactTable({
		columns,
		data: data || [],
		state: {
			showGlobalFilter: true,
			density: 'compact',
			expanded: true, //expand all groups by default
			pagination: { pageIndex: 0, pageSize: 25 },
			sorting: [{ id: 'name', asc: true }], //sort by group by default},
		},
		muiTablePaperProps: {
			elevation: 0, //change the mui box shadow
			sx: {
				'& tr:nth-of-type(even)': {
					backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
				},
				minWidth: '800px',
			},
		},
		displayColumnDefOptions: {
			'mrt-row-actions': {
				muiTableHeadCellProps: {
					align: 'center',
				},
			},
		},
	});

	//display if loading
	if (isLoading) {
		return (
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					Unique IDs
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center' }} mb={2}>
					<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
						The IDs below are unique and are guaranteed to not be represented elsewhere. These IDs should be used to
						blind a provider when referring to them about sensitive information in documents and minutes.
					</Typography>
				</Box>

				<LoadingSpinner />

				<br />
			</PageCards>
		);
	}

	return (
		<Fragment>
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					Unique IDs
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center' }} mb={2}>
					<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
						The IDs below are unique and are guaranteed to not be represented elsewhere. These IDs should be used to
						blind a provider when referring to them about sensitive information in documents and minutes.
					</Typography>
				</Box>

				{claims?.claims?.admin && (
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<MaterialReactTable table={table} />
					</Box>
				)}
			</PageCards>
			<br />
		</Fragment>
	);
}
